import Dialog from "@mui/material/Dialog";
import { MdClose } from "react-icons/md";
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";
import { useContext, useEffect, useState } from "react";
import QuantityBox from "../QuantityBox";
import { IoIosHeartEmpty, IoMdHeartEmpty } from "react-icons/io";
import { MdOutlineCompareArrows } from "react-icons/md";
import { MyContext } from "../../App";
import ProductZoom from "../ProductZoom";
import { IoCartSharp } from "react-icons/io5";
import { editData, fetchDataFromApi, postData } from "../../utils/api";
import { FaHeart } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsCartFill } from "react-icons/bs";
import { deleteData } from "../../utils/api";

const ProductModal = (props) => {
  const [productQuantity, setProductQuantity] = useState();
  const [chengeQuantity, setchengeQuantity] = useState(0);
  let [cartFields, setCartFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [activeSize, setActiveSize] = useState(null);
  const [activeColor, setActiveColor] = useState(null);
  const history = useNavigate();
  const [tabError, setTabError] = useState(false);
  const [isAddedToMyList, setSsAddedToMyList] = useState(false);
  const [productData, setProductData] = useState([]);
  const [myListData, setmyListData] = useState([]);

  const context = useContext(MyContext);

  useEffect(() => {
    if (
      props?.data?.productRam.length === 0 &&
      props?.data?.productWeight.length === 0 &&
      props?.data?.size.length === 0
    ) {
      setActiveSize(1);
    }

    const user = JSON.parse(localStorage.getItem("user"));

    fetchDataFromApi(
      `/api/my-list?productId=${props?.data?.id}&userId=${user?.userId}`
    ).then((res) => {
      if (res.length !== 0) {
        setSsAddedToMyList(true);
      }
    });
  }, []);

  const quantity = (val) => {
    setProductQuantity(val);
    setchengeQuantity(val);
  };

  const isActive = (index) => {
    setActiveSize(index);
    setTabError(false);
  };
  const isActiveColor = (index) => {
    setActiveColor(index);
    setTabError(false);
  };

  //   const addtoCart = () => {
  //     if (activeSize !== null) {
  //       if ( activeColor === null) {
  //         setTabError(true);
  //         context.setAlertBox({
  //           open: true,
  //           error: true,
  //           msg: "Please select color ",
  //         });

  //         return; // Stop further execution
  //       }
  //       const user = JSON.parse(localStorage.getItem("user"));

  //       cartFields.productTitle = props?.data?.name;
  //       cartFields.image = props?.data?.images[0];
  //       cartFields.rating = props?.data?.rating;
  //       cartFields.price = props?.data?.price;
  //       cartFields.quantity = productQuantity;
  //       cartFields.subTotal = parseInt(props?.data?.price * productQuantity);
  //       cartFields.productId = props?.data?.id;
  //       cartFields.countInStock = props?.data?.countInStock;
  //       cartFields.userId = user?.userId;
  //       cartFields.size = props?.data?.size[activeSize];
  //       cartFields.color = props?.data?.color[activeColor];

  // console.log(cartFields)
  //       context.addToCart(cartFields);

  //     } else {
  //       setTabError(true);
  //       context.setAlertBox({
  //         open: true,
  //         error: true,
  //         msg: "Please select size",
  //       });
  //     }
  //   };

  const addtoCart = () => {
    if (activeSize !== null) {
      if (activeColor === null) {
        setTabError(true);
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Please select color ",
        });

        return; // Stop further execution
      }
      const user = JSON.parse(localStorage.getItem("user"));

      cartFields.productTitle = props?.data?.name;
      cartFields.image = props?.data?.images[0];
      cartFields.rating = props?.data?.rating;
      cartFields.price = props?.data?.price;
      cartFields.quantity = productQuantity;
      cartFields.subTotal = parseInt(props?.data?.price * productQuantity);
      cartFields.productId = props?.data?.id;
      cartFields.countInStock = props?.data?.countInStock;
      cartFields.userId = user?.userId;
      cartFields.size = props?.data?.size[activeSize];
      cartFields.color = props?.data?.color[activeColor];

      console.log(cartFields);
      context.addToCart(cartFields);
    } else {
      setTabError(true);
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please select size",
      });
    }
  };

  //   const shopnow = async () => {
  //     if (activeSize !== null ) {
  //       if ( activeColor === null) {
  //         setTabError(true);
  //         context.setAlertBox({
  //           open: true,
  //           error: true,
  //           msg: "Please select color ",
  //         });

  //         return; // Stop further execution
  //       }
  //       const user = JSON.parse(localStorage.getItem("user"));

  //       cartFields.productTitle = props?.data?.name;
  //       cartFields.image = props?.data?.images[0];
  //       cartFields.rating = props?.data?.rating;
  //       cartFields.price = props?.data?.price;
  //       cartFields.quantity = productQuantity;
  //       cartFields.subTotal = parseInt(props?.data?.price * productQuantity);
  //       cartFields.productId = props?.data?.id;
  //       cartFields.countInStock = props?.data?.countInStock;
  //       cartFields.userId = user?.userId;
  //       cartFields.size = props?.data?.size[activeSize];
  //       cartFields.color = props?.data?.color[activeColor];

  //       await context.addToCart(cartFields);

  //       // Yeh check add kar diya hai
  //       if (user) {
  //         context.setisOpenProductModal(false)
  //         history("/checkout");
  //       }

  //     } else {
  //       setTabError(true);
  //       context.setAlertBox({
  //         open: true,
  //         error: true,
  //         msg: "Please select size",
  //       });
  //     }
  //   }

  const shopnow = async () => {
    if (activeSize !== null) {
      if (activeColor === null) {
        setTabError(true);
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Please select color",
        });
        return; // Stop further execution
      }

      const user = JSON.parse(localStorage.getItem("user"));

      // Create productDirectOrder object instead of using cartFields
      const productDirectOrder = {
        productTitle: props?.data?.name,
        image: props?.data?.images[0],
        rating: props?.data?.rating,
        price: props?.data?.price,
        quantity: productQuantity,
        subTotal: parseInt(props?.data?.price * productQuantity),
        productId: props?.data?.id,
        countInStock: props?.data?.countInStock,
        userId: user?.userId,
        size: props?.data?.size[activeSize],
        color: props?.data?.color[activeColor],
      };

      // Instead of adding to cart, navigate with productDirectOrder data
      if (user) {
        context.setisOpenProductModal(false);
        history("/ordercheckout", { state: { productDirectOrder } });
      }
    } else {
      setTabError(true);
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please select size",
      });
    }
  };

  //   const addToMyList = (id) => {
  //     const user = JSON.parse(localStorage.getItem("user"));

  //     if (user !== undefined && user !== null && user !== "") {
  //       const data = {
  //         productTitle: props?.data?.name,
  //         image: props?.data?.images[0],
  //         rating: props?.data?.rating,
  //         price: props?.data?.price,
  //         productId: id,
  //         userId: user?.userId,
  //       };

  //       postData(`/api/my-list/add/`, data).then((res) => {
  //         if (res.status !== false) {
  //           // Update the state to reflect that the item has been added
  //           setSsAddedToMyList(true);
  //           context.setAlertBox({
  //             open: true,
  //             error: false,
  //             msg: "The product was added to my list",
  //           });
  //         } else {
  //           context.setAlertBox({
  //             open: true,
  //             error: true,
  //             msg: res.msg,
  //           });
  //         }
  //       });
  //     } else {
  //       context.setAlertBox({
  //         open: true,
  //         error: true,
  //         msg: "Please Login to continue",
  //       });
  //     }
  //   };

  const removeItem = (id) => {
    setIsLoading(true);
    deleteData(`/api/my-list/${id}`).then((res) => {
      context.setAlertBox({
        open: true,
        error: false,
        msg: "item removed from My List!",
      });

      const user = JSON.parse(localStorage.getItem("user"));
      fetchDataFromApi(`/api/my-list?userId=${user?.userId}`).then((res) => {
        setmyListData(res);
        setIsLoading(false);
      });
    });
  };

  const toggleMyList = async (id) => {
    if (isLoading) return; // If a request is already in progress, don't allow further clicks

    setIsLoading(true); // Set loading to true while processing
    const user = JSON.parse(localStorage.getItem("user"));

    if (user !== undefined && user !== null && user !== "") {
      try {
        // Check if product is already in My List
        const res = await fetchDataFromApi(
          `/api/my-list?productId=${id}&userId=${user?.userId}`
        );
        if (res.length !== 0) {
          // If product is in My List, remove it
          await removeItem(res[0]._id);
          setSsAddedToMyList(false); // Update the heart icon to empty
        } else {
          // If product is not in My List, add it
          const data = {
            productTitle: props?.item?.name,
            image: props?.item?.images[0],
            rating: props?.item?.rating,
            price: props?.item?.price,
            productId: id,
            userId: user?.userId,
          };
          const response = await postData(`/api/my-list/add/`, data);
          if (response.status !== false) {
            context.setAlertBox({
              open: true,
              error: false,
              msg: "Product added to My List",
            });
            setSsAddedToMyList(true); // Update heart icon to filled
          } else {
            context.setAlertBox({
              open: true,
              error: true,
              msg: response.msg,
            });
          }
        }
      } catch (error) {
        console.log("Error occurred:", error);
        context.setAlertBox({
          open: true,
          error: true,
          msg: "Something went wrong! Please try again.",
        });
      } finally {
        setIsLoading(false); // Reset the loading state after the request is done
      }
    } else {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please Login to continue",
      });
      setIsLoading(false); // Reset loading state if user is not logged in
    }
  };
  return (
    <>
      <Dialog
        open={context.isOpenProductModal}
        className="productModal"
        onClose={() => context.setisOpenProductModal(false)}
      >
        <Button
          className="close_"
          onClick={() => context.setisOpenProductModal(false)}
        >
          <MdClose />
        </Button>
        <h4 class="mb-1 font-weight-bold pr-5">{props?.data?.name}</h4>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center mr-4">
            <span>Brands:</span>
            <span className="ml-2">
              <b>{props?.data?.brand}</b>{" "}
            </span>
          </div>

          <Rating
            name="read-only"
            value={parseInt(props?.data?.rating)}
            size="small"
            precision={0.5}
            readOnly
          />
        </div>

        <hr />

        <div className="row mt-2 productDetaileModal">
          <div className="col-md-5">
            <ProductZoom
              images={props?.data?.images}
              discount={props?.data?.discount}
            />
          </div>

          <div className="col-md-7">
            <div className="d-flex info align-items-center mb-3">
              <span className="oldPrice lg mr-2">
                Rs: {props?.data?.oldPrice}
              </span>
              <span className="netPrice text-danger lg">
                Rs: {props?.data?.price}
              </span>
            </div>

            <span className="badge bg-success">IN STOCK</span>

            <p className="mt-3"> {props?.data?.description}</p>

            {props?.data?.productRam?.length !== 0 && (
              <div className="productSize d-flex align-items-center">
                <span>RAM:</span>
                <ul
                  className={`list list-inline mb-0 pl-4 ${
                    tabError === true && "error"
                  }`}
                >
                  {props?.data?.productRam?.map((item, index) => {
                    return (
                      <li className="list-inline-item">
                        <a
                          className={`tag ${
                            activeSize === index ? "active" : ""
                          }`}
                          onClick={() => isActive(index)}
                        >
                          {item}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {props?.data?.size?.length !== 0 && (
              <div className="productSize d-flex align-items-center">
                <span>Size:</span>
                <ul
                  className={`list list-inline mb-0 pl-4 ${
                    tabError === true && "error"
                  }`}
                >
                  {props?.data?.size?.map((item, index) => {
                    return (
                      <li className="list-inline-item">
                        <a
                          className={`tag ${
                            activeSize === index ? "active" : ""
                          }`}
                          onClick={() => isActive(index)}
                        >
                          {item}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {props?.data?.color?.length !== 0 && (
              <div className="productSize d-flex align-items-center">
                <span>Color:</span>
                <ul
                  className={`list list-inline mb-0 pl-4 ${
                    tabError === true && "error"
                  }`}
                >
                  {props?.data?.color?.map((item, index) => {
                    return (
                      <li className="list-inline-item">
                        <a
                          className={`tag ${
                            activeColor === index ? "active" : ""
                          }`}
                          onClick={() => isActiveColor(index)}
                        >
                          {item}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {props?.data?.productWeight?.length !== 0 && (
              <div className="productSize d-flex align-items-center">
                <span>Weight:</span>
                <ul
                  className={`list list-inline mb-0 pl-4 ${
                    tabError === true && "error"
                  }`}
                >
                  {props?.data?.productWeight?.map((item, index) => {
                    return (
                      <li className="list-inline-item">
                        <a
                          className={`tag ${
                            activeSize === index ? "active" : ""
                          }`}
                          onClick={() => isActive(index)}
                        >
                          {item}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            <div className="d-flex align-items actions_ mt-3">
              <QuantityBox quantity={quantity} item={props?.data} />

            
            </div>

            <div className="d-flex align-items-center mt-4 actions">
         
              <Button
                className=" btn-lg btn-big btn-round colorbtnmy"
                onClick={() => addtoCart()}
              >
                <BsCartFill /> &nbsp;
                {context.addingInCart === true ? "adding..." : " Add to cart"}
              </Button>
              <Button
                className=" btn-lg btn-big btn-round colorbtnmy ml-3"
                onClick={() => shopnow()}
              >
                ORDER NOW
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ProductModal;
