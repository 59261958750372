import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { postData } from '../../utils/api';

const CardPayment = () => {
  const history = useNavigate();

  const handlePaymentMethod = (method) => {
    // تاریخ اور وقت کے ساتھ موجودہ آرڈر کی تفصیلات حاصل کریں
    const user = JSON.parse(localStorage.getItem("user"));
    const orderInfo = {
      ...user,
      method: "cash", // 'cash' یا 'card'
    };

    // اگر 'cash' ہے تو آرڈر کو فوری طور پر پوسٹ کریں
    if (method === 'cash') {
      postData(`/api/orders/create`, orderInfo).then((res) => {
        history("/orders"); // کامیاب جمع کرانے کے بعد صارف کو آرڈر پیج پر لے جائیں
      });
    } else {
      // 'card' کے لیے آپ کو کارڈ پیمنٹ گیٹ وے کو انٹیگریٹ کرنا ہوگا
      // یہاں آپ Razorpay یا Stripe کو انٹیگریٹ کر سکتے ہیں
      history("/payment"); // کارڈ پیمنٹ کے لیے ایک نیا پیج
    }
  };

  return (
    <div>
      <h2>Select Payment Method</h2>
      <Button onClick={() => handlePaymentMethod('cash')}>Cash on Delivery</Button>
      <Button onClick={() => handlePaymentMethod('card')}>Card Payment</Button>
    </div>
  );
};


export default CardPayment;