import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { IoBagCheckOutline } from "react-icons/io5";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import emprtCart from '../../assets/images/emptyCart.png';
import thanks from '../../assets/images/thanku.png';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { MyContext } from "../../App";
import { fetchDataFromApi, postData, deleteData } from "../../utils/api";

import { Link, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
const Checkout = () => {
  const [province, setprovince] = useState('');

  const [openDialog, setOpenDialog] = useState(false); // Manage the dialog state

  const handleDialogClose = () => {
    setOpenDialog(false);
    history("/orders"); // Navigate to orders page when dialog is closed
  };


  const handleChange = (event) => {
    setprovince(event.target.value);
  };


  const [formFields, setFormFields] = useState({
    fullName: "",
    country: "",
    streetAddressLine1: "",
    streetAddressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    email: "",
  });

  const [cartData, setCartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState();


  useEffect(() => {
    const fetchData = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    await  fetchDataFromApi(`/api/cart?userId=${user?.userId}`).then((res) => {
        setCartData(res);
  
        setTotalAmount(
          res.length !== 0 &&
          res
            .map((item) => parseInt(item.price) * item.quantity)
            .reduce((total, value) => total + value, 0
            )
        );
      });
    };

    fetchData();
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(`/api/cart?userId=${user?.userId}`).then((res) => {
      setCartData(res);

      setTotalAmount(
        res.length !== 0 &&
        res
          .map((item) => parseInt(item.price) * item.quantity)
          .reduce((total, value) => total + value, 0
          )
      );
    });
  }, []);

  const onChangeInput = (e) => {
    setFormFields(() => ({
      ...formFields,
      [e.target.name]: e.target.value,
    }));
  };

  const context = useContext(MyContext);
  const history = useNavigate();



const checkout = (e) => {


  e.preventDefault();

  const user = JSON.parse(localStorage.getItem("user"));
  const checkoutProduct = JSON.parse(localStorage.getItem("checkoutProduct"));

  // If no cart data is available, use checkoutProduct
  const cartDataToUse = cartData?.length ? cartData : [checkoutProduct]; // Check cartData or fallback to checkoutProduct

  if (!cartDataToUse.length) {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "No product in cart",
    });
    return;
  }

  // Validation checks
  if (formFields.fullName === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill full name ",
    });
    return false;
  }

  if (formFields.country === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill country ",
    });
    return false;
  }

  if (formFields.streetAddressLine1 === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill Street address",
    });
    return false;
  }

  if (formFields.city === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill city ",
    });
    return false;
  }

  if (formFields.zipCode === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill zipCode ",
    });
    return false;
  }

  if (formFields.phoneNumber === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill phone Number ",
    });
    return false;
  }
  if (formFields.state === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill State ",
    });
    return false;
  }

  if (formFields.email === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill email",
    });
    return false;
  }

  // Further validation checks...
  
  // Order details
  const addressInfo = {
    name: formFields.fullName,
    phoneNumber: formFields.phoneNumber,
    address: formFields.streetAddressLine1 + formFields.streetAddressLine2,
    pincode: formFields.zipCode,
    date: new Date()
    // .toLocaleString("en-US", {
    //   month: "short",
    //   day: "2-digit",
    //   year: "numeric",
    // }),
  };

  const payLoad = {
    name: addressInfo.name,
    phoneNumber: formFields.phoneNumber,
    address: addressInfo.address,
    pincode: addressInfo.pincode,
    amount: parseInt(totalAmount), // Ensure this is correct
    email: user.email,
    userid: user.userId,
    products: cartData,
    date: addressInfo.date,
  };

  console.log(payLoad);
  console.log(cartData);


  // Post order
  postData(`/api/orders/create`, payLoad).then((res) => {
    fetchDataFromApi(`/api/cart?userId=${user?.userId}`).then((res) => {
      res?.length !== 0 &&
        res?.map((item) => {
          deleteData(`/api/cart/${item?.id}`).then((res) => {});
        });
      setTimeout(() => {
        context.getCartData();
      }, 1000);
      setOpenDialog(true); // Open the Thank You dialog
    });
  });

 
};


  return (

    <div>
    { cartData?.length !== 0 ? (
      <section className="section">
      <div className="container">
      <form className="checkoutForm" onSubmit={checkout}>
        <div className="row">
          <div className="col-md-8">
            <h2 className="hd">BILLING DETAILS</h2>

            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    label="Full Name *"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="fullName"
                    onChange={onChangeInput}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    label="Country *"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="country"

                    onChange={onChangeInput}
                  />
                </div>
              </div>
            </div>

            <h6>Street address *</h6>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    label="House number and street name"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="streetAddressLine1"
                    onChange={onChangeInput}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    label="Apartment, suite, unit, etc. (optional)"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="streetAddressLine2"
                    onChange={onChangeInput}
                  />
                </div>
              </div>
            </div>

            <h6>Town / City *</h6>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    label="City"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="city"
                    onChange={onChangeInput}
                  />
                </div>
              </div>
            </div>

            <h6>Provinces</h6>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">

                  <TextField
                    label="State"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="state"
                    onChange={onChangeInput}
                  />

                
                </div>
              </div>
            </div>

            <h6>Postcode / ZIP *</h6>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    label="ZIP Code"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="zipCode"
                    onChange={onChangeInput}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="phoneNumber"
                    onChange={onChangeInput}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    className="w-100"
                    size="small"
                    name="email"
                    onChange={onChangeInput}
                  />
                </div>
              </div>
            </div>
          </div>



          <div className="col-md-4">
            <div className="card border p-3 cartDetails">
           
            <h4>YOUR CART TOTALS</h4> 
            
              <div className="d-flex align-items-center mb-3">
                                            <span>Total Items</span>
                                            <span className="ml-auto"><b>{context.cartData?.length>0 ? context.cartData?.length : 0}</b></span>
                                        </div>

              <div className="d-flex align-items-center mb-3">
                <span>Subtotal</span>
                <span className="ml-auto text-red font-weight-bold">
                  <b>
                {
      (cartData?.length !== 0 
          ? cartData.map(item => parseInt(item.price) * item.quantity)
              .reduce((total, value) => total + value, 0) 
          : 0
      )?.toLocaleString('en-US', { style: 'currency', currency: 'PKR' })
  }
  </b>
                </span>
              </div>

              <div className="d-flex align-items-center mb-3">
                <span>Shipping</span>
                <span className="ml-auto"><b> FREE </b></span>
              </div>

              <div className="d-flex align-items-center mb-3">
                <span>Estimate for</span>
                <span className="ml-auto"><b>KARACHI</b></span>
              </div>

              <div className="d-flex align-items-center">
                <span>Total</span>
                <span className="ml-auto text-red font-weight-bold">
                <b>
                {
    (cartData?.length !== 0 
        ? cartData.map(item => parseInt(item.price) * item.quantity)
            .reduce((total, value) => total + value, 0) //+ 199 // shipping charges ko reduce ke baad ek martaba add karna
        : 199
    )?.toLocaleString('en-US', { style: 'currency', currency: 'PKR' })
}
</b>
                </span>
              </div>


              <br />
              <Button
                type="submit"
                className="btn-blue bg-red btn-lg btn-big"
              >
                <IoBagCheckOutline /> &nbsp; Submit Order
              </Button>

            </div>
          </div>
        </div>
      </form>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
      >
       
        <DialogContent>
        <div className=" d-flex align-items-center justify-content-center flex-column">
      <img src={thanks} width="350" />
    
  </div>  
         <h1 className="thankuh1">   Your order has been placed successfully.</h1>
         
        <div className="thankudialogeafterordersubmit ">
          <Link to="/"> <Button className='btn-blue colorbtnmy btn-lg btn-big btn-round'><FaHome /> &nbsp; Continue Shopping</Button></Link> 
          <Button  onClick={handleDialogClose} className='btn-blue colorbtnmy btn-lg btn-big btn-round'> Go TO Orders Page</Button> 
          </div> 

          
        </DialogContent>
       
      </Dialog>

    </div>
    </section>
    ) : (
      <div className="empty d-flex align-items-center justify-content-center flex-column">
      <img src={emprtCart} width="150" />
      <h3>Your Cart is currently empty</h3>
      <br />
      <Link to="/"> <Button className='btn-blue colorbtnmy btn-lg btn-big btn-round'><FaHome /> &nbsp; Continue Shopping</Button></Link>
  </div>
    )}
  </div>


   
  )
  
};

export default Checkout;
